<template>
  <v-dialog max-width="700px" persistent scrollable v-model="showDialog">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('generic.lang_addItem') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn @click="closeDialog" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="min-height: 100px !important; overflow: hidden">
        <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard($event);items_menu=false" dense :label="$t('erp.lang_nav_waresearch')" :loading="loadingSearch" outlined style="margin-top: 20px;"
                      v-model="searchWare"></v-text-field>

        <v-menu
            max-height="300px"
            class="white"
            v-model="items_menu"
            open-on-focus
            offset-y
            close-on-content-click
            :close-on-click="false"
        >
          <template v-slot:activator="{}">
            <v-text-field
                :label="$t('generic.lang_waregroup')"
                hide-details
                outlined
                ref="autocomplete"
                dense
                v-model="searchWaregroup.name"
                :loading="isLoading"
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                @focus="showTouchKeyboard($event);items_menu=true;"
                @keyup="items_menu = true"
                @click="items_menu = true"
                :append-icon="items_menu?'mdi-chevron-up':'mdi-chevron-down'"
            >
            </v-text-field>
          </template>

          <v-list v-if="filteredItemGroups.length > 0">
            <v-list-item
                :key="group.id"
                :color="group.id === searchWaregroup.id?'primary':''"
                v-for="group in filteredItemGroups"
                @click="searchWaregroup.id = group.id;searchWaregroup.name=group.name"
            >
              <v-list-item-content v-html="group.name">
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item>
              <v-list-item-content>
                {{$t("generic.lang_noDataToDisplay")}}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-text>

      <v-divider class="pa-0 ma-0"></v-divider>

      <v-card-text style="max-height: 500px; padding: 0;" v-if="items.length > 0">
        <v-list>
          <v-list-item :key="item.id" @click="selectItem(item)" v-for="item in items">
            <v-list-item-content>
              <v-list-item-title v-if="(item.color && item.size)">{{ item.name }}
                ({{ $t('erp.lang_warecreate_color') + ':' + item.color + ' , ' + $t('generic.lang_size') + ':' + item.size }})
              </v-list-item-title>
              <v-list-item-title v-if="(item.color && item.size)">{{ item.name }}
                ({{ $t('erp.lang_warecreate_color') + ':' + item.color }})
              </v-list-item-title>
              <v-list-item-title v-if="(item.color && item.size)">{{ item.name }}
                ({{ $t('generic.lang_size') + ':' + item.size }})
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ item.itemgroupName }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-text v-else style="margin-top: 20px;">
        <v-alert color="primary" text>{{ $t('erp.lang_alert_no_item_4_id') }}</v-alert>
      </v-card-text>
      <div id="onScreenKeyboardDivWare">
        <vue-touch-keyboard id="onScreenKeyboard"
                            class="internalWidth"
                            :options="touchKeyboardWare.options"
                            :defaultKeySet="touchKeyboardWare.keySet"
                            v-if="touchKeyboardWare.visible"
                            :layout="touchKeyboardWare.layout"
                            :cancel="hidetouchKeyboardWare"
                            :accept="hidetouchKeyboardWare"
                            :input="touchKeyboardWare.input"/>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import {ENDPOINTS,KEYBOARD} from "@/config";

export default {
  name: "ItemsDialog",

  props: {
    showDialog: Boolean
  },
  data() {
    return {
      searchWare: "",
      searchWaregroup: {
        name:'',
        id:'',
      },
      KEYBOARD,
      touchKeyboardWare: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      loadingSearch: false,
      items_menu: false,
      isLoading: false,
      awaitingSearch: null,
      items: [],
      itemGroups: []
    }
  },
  computed:{
    ...mapState([
      'touchkeyboard',
    ]),
    filteredItemGroups(){
      if(this.searchWaregroup.name && this.searchWaregroup.name.length>0) {
        return this.itemGroups.filter(group => group.name.toLowerCase().includes(this.searchWaregroup.name.toLowerCase()));
      }else {
        return this.itemGroups;
      }
    }
  },
  watch: {
    searchWare() {
      this.loadingSearch = true;
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        this.searchForItem()
      }, 500);
    },
    'searchWaregroup.name':function() {
      this.items_menu=true;
    },
    'searchWaregroup.id':function() {
      this.loadingSearch = true;
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        this.searchForItem()
      }, 500);
    },
    async showDialog(val) {
      if (val) {
        this.itemGroups = await this.$store.dispatch("itemgroups/getItemgroups");
        document.addEventListener('click', this.dismissOnScreenKeyboardWare);
      }else{
        document.removeEventListener('click', this.dismissOnScreenKeyboardWare);
      }
    }
  },

  methods: {
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardWare.input = e.target;
      this.touchKeyboardWare.layout = e.target.dataset.layout;

      if (!this.touchKeyboardWare.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardWare.visible = true;
        }, 150);
      }
    },
    hidetouchKeyboardWare() {
      if (this.touchKeyboardWare.visible) {
        this.touchKeyboardWare.visible = false;
      }
    },
    dismissOnScreenKeyboardWare: function (e) {
      if (this.touchkeyboard.settings.enabled && this.$refs.onScreenKeyboardDivWare) {
        if (!this.$refs.onScreenKeyboardDivWare.contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hidetouchKeyboardWare();
          }
        }
      }
    },
    closeDialog() {
      this.$emit("close");
    },
    selectItem(item) {
      this.$emit("selectItem", item);
    },
    searchForItem() {
      this.axios.post(ENDPOINTS.ERP.ITEM.POSLAYOUTSEARCH, {
        query: this.searchWare,
        waregroupID: this.searchWaregroup.id
      }).then((res) => {
        this.items = res.data.map((item) => {
          // GET ITEMGROUP
          let itemgroup = this.filteredItemGroups.find((itemgroup) => itemgroup.id === item.itemgroupID);

          if(!itemgroup) {
            item.itemgroupName = "";
          }
          else {
            item.itemgroupName = itemgroup.name;
          }

          return item;
        });

        this.loadingSearch = false;
      });
    },
  }
}
</script>
