<template>
    <v-dialog :value="showDialog" persistent scrollable max-width="700px">
        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title>{{ $t('generic.lang_selectColor') }}</v-toolbar-title>

                <v-spacer/>

                <v-btn icon @click="closeDialog">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text style="max-height: 700px; padding: 0;">
                <swatches v-model="selectedColor" inline></swatches>

                <!-- Remove color -->
                <v-btn color="error" text v-if="selectedColor !== null" @click="removeGridItemColor">{{ $t('generic.lang_removeColor') }}</v-btn>
            </v-card-text>

            <v-card-actions>
                <v-btn text color="primary" @click="closeDialog">{{$t("generic.lang_cancel")}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="selectedColor === null" @click="saveColor">{{ $t('generic.lang_saveColor') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Swatches from 'vue-swatches';
    import "vue-swatches/dist/vue-swatches.css"

    export default {
        name: "ColorPickerDialog",

        props: {
            showDialog: Boolean,
            gridItem: Object
        },

        data() {
            return {
                selectedColor: null
            }
        },

        components: {
            Swatches
        },

        watch: {
            showDialog(val) {
                if(!val) {
                    this.selectedColor = null;
                }
                else {
                    if(this.gridItem !== null) {
                        this.selectedColor = this.gridItem.color;
                    }
                }
            }
        },

        methods: {
            closeDialog() {
                this.$emit("close");
            },
            saveColor() {
                this.$emit("saveColor", this.selectedColor);
            },
            removeGridItemColor() {
                this.selectedColor = null;
                this.saveColor();
            }
        }
    }
</script>