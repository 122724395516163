<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="700px">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('generic.lang_addItemgroup') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-tabs>
        <v-tab>{{ $t('erp.lang_nav_waregroups') }}</v-tab>
        <v-tab>{{ $t('erp.lang_waregroupsCreate') }}</v-tab>

        <v-tab-item>
          <v-card-text style="overflow-x: auto; max-height: 500px; padding: 0;">
            <v-list>
              <v-list-item @click="selectWaregroup(waregroup)" v-for="waregroup in filteredItemGroups"
                           :key="waregroup.id">
                <v-list-item-content>
                  <v-list-item-title>{{ waregroup.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <v-card-text>
            <v-text-field @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          dense outlined
                          v-model="virtWaregroupName"
                          :label="$t('settings.lang_virtualItemgroup')"></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="createVirtWaregroup">{{ $t('generic.lang_save') }}</v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs>


      <div id="onScreenKeyboardDivWaregroup">
        <vue-touch-keyboard id="onScreenKeyboard"
                            class="internalWidth"
                            :options="touchKeyboardWaregroup.options"
                            :defaultKeySet="touchKeyboardWaregroup.keySet"
                            v-if="touchKeyboardWaregroup.visible"
                            :layout="touchKeyboardWaregroup.layout"
                            :cancel="hidetouchKeyboardWaregroup"
                            :accept="hidetouchKeyboardWaregroup"
                            :input="touchKeyboardWaregroup.input"/>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import {KEYBOARD} from "@/config";

export default {
  name: "WaregroupsDialog",

  props: {
    showDialog: Boolean,
    isForDelivery: Boolean,
  },

  data() {
    return {
      virtWaregroupName: "",
      filteredItemGroups: [],
      KEYBOARD,
      touchKeyboardWaregroup: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
    }
  },
  computed:{
    ...mapState([
        'touchkeyboard',
    ])
  },
  watch: {
    async showDialog(val) {
      if (val) {
        this.filteredItemGroups = await this.$store.dispatch("itemgroups/getItemgroups");
        document.addEventListener('click', this.dismissOnScreenKeyboardWaregroup);
      }else{
        document.removeEventListener('click', this.dismissOnScreenKeyboardWaregroup);
      }
    }
  },

  methods: {
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardWaregroup.input = e.target;
      this.touchKeyboardWaregroup.layout = e.target.dataset.layout;

      if (!this.touchKeyboardWaregroup.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardWaregroup.visible = true;
        }, 150);
      }
    },
    hidetouchKeyboardWaregroup() {
      if (this.touchKeyboardWaregroup.visible) {
        this.touchKeyboardWaregroup.visible = false;
      }
    },
    dismissOnScreenKeyboardWaregroup: function (e) {
      if (this.touchkeyboard.settings.enabled && this.$refs.onScreenKeyboardDivWaregroup) {
        if (!this.$refs.onScreenKeyboardDivWaregroup.contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hidetouchKeyboardWaregroup();
          }
        }
      }
    },
    closeDialog() {
      this.$emit("close");
    },
    selectWaregroup(waregroup) {
      this.$emit("selectWaregroup", waregroup);
    },
    createVirtWaregroup() {
      this.$emit("selectWaregroup", {
        name: this.virtWaregroupName
      }, true);

      this.virtWaregroupName = "";
    }
  }
}
</script>
